/**Overrides to make header css work in vue controlled contexts like dx and dashboards*/
.mrc-header {
	background-color: #3a6ea7;
	height: $header-height;
    z-index: 1030;
    &.navbar{
        align-items: center;
    }
}
.vue-body {
    .fixed-top{
        position:inherit;
        &.mrc-header{
            .navbar-brand{
                font-size:1.25rem;
                img {
                    height:inherit
                }
            }
        }
    }
    .page-nav{
        top:60px;
    }
}

