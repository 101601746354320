// Existing styles for pivot/irt taken from mrc_Servlet_ajax.css
 
.available-dimensions {
	min-height: 50px;
}

#data_pane {
	border: 1px solid rgba(0,0,0,.125);	
	border-top: $bold-border-top;
	position: relative;
    display: -ms-flexbox;
    display: -webkit-box;
    display: inline-flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;  
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box; 
    border-radius: .3rem;    
	padding: 1rem;
	min-width: 100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	
}



.irt-content #sort_table_head th {
	background: $white;
}

.irt-content #sort_table_head th.highlight {
	background: #d6e1e7;
}
.irt-content .card {
	border: none;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.irt-content #mainpane .card-body {
	padding: 0!important;
	color: #37474f;
}

.irt-content .card-body .menu_list .menu_item{
	padding: 1.25rem;
	background: $white !important;
    color: $bold-font-color;
}

.irt-content .card-body #select_menu_display .menu_item{
	padding: 1.25rem;
}

.irt-content .card-body #select_menu_display, .irt-content .card-body #select_menu {
	background: $white !important;
	border-right: 1px solid $border-color;
	border-left: 1px solid $border-color;
	color: #37474f
}


li.irt-dimension.ui-sortable-handle:after {
	content: '\f047';
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin-left: 5px;
    color: $white;
    right: 0;
    float: right;
}

.irt-dimensions {
	padding: 0;
}


#selected-dimensions .irt-dimension {
	cursor: move;
	background: $primary;
	list-style: none;
	padding: 4px;
	margin-top: 3px;
	border-radius: .25rem;
    padding: .5rem;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 1px;
	color: $white;
}

#available-dimensions .irt-dimension {
	cursor: move;
	background: $white;
	list-style: none;
	padding: 4px;
	margin-top: 3px;
	border-radius: .25rem;
    padding: .5rem;
	border: 1px solid #78909c;
	color: #78909c;
}

@keyframes drag-dimensions {
	0%   {background-color: $primary;transform: scale(1);}
	25%  {background-color: #3c7fc3;transform: scale(0.99);}
	50%  {background-color: $primary;transform: scale(1);}
	75% {background-color: #3c7fc3;transform: scale(0.99);}
	100% {background-color: $primary;transform: scale(1);}
  }

#available-dimensions .irt-dimension:active {
	background:$primary;
	color:$white;
	animation-name: drag-dimensions;
   animation-duration: 4s;
   animation-iteration-count: infinite;
}

#available-dimensions li.irt-dimension.ui-sortable-handle:after {color:#78909c;}


.card .bg-light-alt {
	/* background: #628496 !important; */
	background: #5a7a8a !important;
    color: white;
    font-weight: bold;
    border: 1px solid #ccc;
}

.hideElement {
	display: none;
}

.to-excel-img {
	cursor: pointer;
}

.colorCondition {
	display: none;
}

.hideme, .spin-img {
	display: none;
}

// Dont wrap text next to drill icon
.sub_value {
	white-space: nowrap;
}

.drill_icon {
	height: 15px;
	width: 15px;
	cursor: pointer;
	margin-right: 5px;
}

#runtime_selection th, #runtime_selection {
	border-color: #a5b9c3;
    background-color: $mrcLightBG;
    color: #505f67;
}

#runtime_selection th.table-title {
	border-left: 1px solid #a5b9c3;
    background: $mrcLightBG;
}

#runtime_selection th.table-title i.remove-all {
	color: #505f67;
}

#runtime_selection .prompt td, .prompt th, .prompt thead th {border-bottom: 1px solid #a5b9c3;}

#select_pane #mode_select {padding: 1rem;}


