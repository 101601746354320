.mrc-header-nav {
	a.nav-link {
		color: #fff;

		&:hover {
			color: #E0E0E0;
		}
	}
}

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu a.dropdown-toggle::before {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-submenu a::before {
	transform: rotate(90deg);
	position: absolute;
	left: 6px;
	top: 0.9em;
}

.dropdown-submenu a::after {
	display: none;
}

.dropdown-submenu .dropdown-menu {
	top: 0;
	margin-left: 0.1rem;
	margin-right: 0.1rem;
}
