body.login-page {
	font-family: arial;
	margin: 0!important;
	background-color: #e5edf5!important;
}



.login-page {
	.navbar {
		background: #4686cb!important;
	}

	.card-header {
		text-align: center;
		padding-top: 1.5rem;
		background: none;
		border-top: 2px solid #4686cb;
		padding-bottom: 1.25rem;
	}

		.card-header h2 {
						font-size: 1.4rem;
						line-height: 1;
						margin-bottom: .5rem!important;
					}

	.login-box	.card {
		background-color: transparent;
		border: none;
		//width: 17rem!important;
		background-color: #fff;
		-webkit-box-shadow: 0 0 5px -1px #b8b8b8;
		-moz-box-shadow: 0 0 5px -1px #b8b8b8;
		box-shadow: 0 0 5px -1px #b8b8b8;
		border-radius: .1875rem!important;
		//border-top: 3px solid #4686cb;
	}

	.login-box .card-body {padding-bottom: 2rem;}

	.btn-wide {
		font-size: .8125rem;
		width: 100%;
		-webkit-box-shadow: 0 0 3px -1px rgba(71,71,71,1);
		-moz-box-shadow: 0 0 3px -1px rgba(71,71,71,1);
		box-shadow: 0 0 3px -1px rgba(71,71,71,1);
		padding: .4375rem .875rem!important;
	}

	.icon-right {
		position: absolute;
		left: 10px;
		top: 10px;
		z-index: 3000;
		color: #dfdfdf;
	}

	.form-control {
		display: block;
		width: 100%;
		height: 2.25003rem;
		padding: .4375rem 2rem!important;
		font-size: .8125rem;
		line-height: 1.5385;
		color: #333;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ddd;
		border-radius: .1875rem!important;
		-webkit-box-shadow: 0 0 0 0 transparent;
		box-shadow: 0 0 0 0 transparent;
		-webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
		transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
	
	}

	.form-control .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
		border-top-right-radius: .1875rem;
		border-bottom-right-radius: .1875rem;
	}

	.form-control::placeholder {
		color: #b8b8b8;
	}

	.form-control {
		color: #a8a8a8;
	}

	.login-box {
		margin-top: 5vh;
		width: auto;

	}

	.btn-success {
		background-color: $primary;
		border-color: $primary!important;
	}
	.errortext{
		color:red;
		text-align:center;
	}

	h5 {
		font-size: .9rem;
		line-height: .8;
		//color: #999;
	}

	.login-box .input-group .fa-2x {font-size: 1.2em;}

	.form-group {margin-bottom: 0;}

	.mrc-login-form {
		padding: .5rem;
		margin-top: 1rem;
	}

}
