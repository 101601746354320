.field-selection-menu {
	max-height: 400px;
	overflow-y: auto;

	div {
		white-space: nowrap;
	}
}

.multiple-sort-menu {
	min-width: 350px;
}

.multiple-sort-menu .form-control {
	width: auto;
}

.dropdown-menu {
	z-index: 3000;
}
