@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'variables';

// Copied and modified from pivot.css
#pvTable.table {
	white-space: nowrap;
	margin: 0;
	border: 1px solid $border-color;
}

#pvTable thead td.corner {
	border-left: 1px solid $border-color;
	border-top: 1px solid $border-color;
}

#pvTable thead td.top {
	border-top: 1px solid $border-color;
}

#pvTable thead td.left {
	border-left: 1px solid $border-color;
}

#pvTable .rowHead {
	 text-align: left;
}

#pvTable .tableData {
	 text-align: right;
	border: 1px solid $border-color;
	}

#pvTable .grand {
	text-align: right;
	font-weight: bold;
	padding: 1rem;
	white-space: nowrap;
	border: 1px solid $border-color;
}

#pvTable .rowGrand {
	font-weight: bold;
	text-align: right;
}

#pvTable thead td img,
#pvTable tr td img {
	cursor: pointer;
}

.graphOptions {
	/*color: #959595;*/
	color: #757575;
	border: none;
	background: $white;
	padding: 3px;
}

#pivotValues {
	text-align: left;
	box-sizing: border-box;
	width: 90%;
	max-width: 600px;
	overflow: auto;
	height: auto;
	background: transparent;
	margin: 0;
	padding: 3px 0 3px 3px;
}

#pivotValues div {
	float: left;
	white-space: nowrap;
	padding: 5px;
}

.pivotCell {
	border-left: none;
	padding: 0;
}

.table td.pivotCell {
	padding: 0;
}

.errortext {
	padding: 0;
}


#pivotContainer #valuesCell {
	border: 1px solid $border-color;
	background: $mrcLightBG!important;
	padding: .5rem 1rem;
}

#pivotContainer .connectedSortable {
	padding: .5rem 1rem;
    border: 1px dashed $border-color!important;
    background: $mrcLightBG!important;
}


#pivotContainer .columnDimensions {
	padding: 1rem 0;
	border: none;
	background: $white!important;
}

#pivotContainer .rowDimensions {
	padding: 0 1rem 0 0 ;
	border: none;
	text-align: center;
	background: $white!important;
}


#pivotContainer #availableCell {
	padding: .5rem 1rem;
	border: 1px solid $border-color;
	background: $mrcLightBG!important;
}

.availableDimensions,
.columnDimensions {
	border-right: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	text-align: left;
	padding: 3px;
}

.availableDimensions {
	border-left: 1px solid $border-color;
}

.rowDimensions {
	border-bottom: 1px solid $border-color;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
	width: 180px;
	text-align: left;
	white-space: nowrap;
	padding: 3px;
	box-sizing: border-box;
}

.availableValues {
	border: 1px solid $border-color;
	text-align: left;
	padding: 3px;
}

#avDimensions {
	text-align: left;
	box-sizing: border-box;
	width: 90%;
	max-width: 600px;
	overflow: auto;
	border: 1px dotted transparent;
	height: auto;
	background: transparent;
	margin: 0;
	padding: 3px 0 3px 3px;
}

#emptyDiv {
	box-sizing: border-box;
	border: none;
	height: 25px;
	text-align: center;
	margin: 0;
	vertical-align: middle;
	background: white!important;
}

#rows {
	text-align: center;
	box-sizing: border-box;
	height: 115px;
	overflow: auto;
	white-space: nowrap;
	border: 1px dotted transparent;
	text-align: center;
	background: transparent;
	padding: 0;
}

#columns {
	text-align: center;
	min-width: 90%;
	overflow: auto;
	min-height: 25px;
	border: 1px dotted transparent;
	text-align: left;
	background: transparent;
	margin: 0;
	padding: 0;
}

#avDimensions div,#columns div {
	float: left;
}

.dimension {
	background: $mrcPrimary;
	cursor: move;
	border-radius: 3px;
	margin: 2px;
	padding: 2px 5px;
	color: $white;
	font-weight: bold;
	white-space: nowrap;
	text-align: center;
	min-width: 110px;
}

div.menu_body span {
	white-space: normal;
}

.menu_item {
	white-space: normal;
	border-bottom: 1px solid $border-color;
	
}

#pvTable thead td {
	background: $mrcLightBG;
	color: $bold-font-color;
	font-weight: bold;
	vertical-align: middle;
	border: 1px solid $border-color;
	padding-top: 4px;
	padding-bottom: 8px;
}

.sort-enabled {
	cursor: pointer;
}

.pivot-graph-table {
	background: $white;
	border: 1px solid $border-color;
	padding: 1rem;
	display: inline-block;
}
.d-mrc-none{
	display:none;
}

