@import "bootstrap/functions";
@import "bootstrap/variables";

$background-detail: #fff;
$background-subtotal1: #f4f7fa;
//$background-subtotal1: #cbdbe6;
$background-subtotal2: #eaeff4;
//$background-subtotal2: #e8eef7;
$background-subtotal3: #e2e8ef;
//$background-subtotal3: #e2eaf5;
$background-subtotal4: #dbe3eb;
//$background-subtotal4: #b9c8eb;
$background-subtotal5: #d3dde6;
//$background-subtotal5: #acdee6;
$background-subtotal6: #cdd7e1;
//$background-subtotal6: #b8d4f0;
$background-grand-total: #DBE5EF ;
//$background-grand-total: #b8d4f0;
$report-font: #555;
table:not(.table-white) {
     .detailBackground {
          background: $background-detail;
          color: $report-font;
     }
     
     .sub1Background {
          background: $background-subtotal1;
          color: $report-font;
     }
     
     .sub2Background {
          background: $background-subtotal2;
          color: $report-font;
     }
     
     .sub3Background {
          background: $background-subtotal3;
          color: $report-font;
     }
     
     .sub4Background {
          background: $background-subtotal4;
          color: $report-font;
     }
     
     .sub5Background {
          background: $background-subtotal5;
          color: $report-font;
     }
     
     .sub6Background {
          background: $background-subtotal6;
          color: $report-font;
     }
     
     
}
.grandBackground {
     background: $background-grand-total;
     color: $primary;
     font-weight: bold;
     border-bottom: 2px solid $primary;
}
.list-group-item {
  cursor: pointer;
}

.list-group-mrc-prompt .list-group-item {
  background: $side-nav-background;

  &.active {
    background: $white;
    border-color: $border-color;
    border-left: 5px solid $info;
    color: $info;
    padding-left: calc(1.25rem - 5px);
  }
}

.hide-prompt {
  display: none;
}

.hide-output-options {
  display: none;
}

.selections-col {
  .form-group {
    margin-bottom: 0;
    border-top: 1px solid $border-color;
    border: 1px solid $border-color;
    border-bottom: none;
    padding: 10px 10px;
    margin-left: 0;
    background: $side-nav-background;
  }

  .form-group.row:last-child,
  .form-row:last-child {
    border-bottom: 1px solid $border-color;
  }

  .form-layout {
    border: none;
    //padding: 0;
  }
}

th.highlight {
  background: $gray-150;
}

.ls.form-control {
  margin-bottom: 3px;
}

.ranking-prompt-table td {
  background: $side-nav-background;
}

.report-table-bordered:not(.table-bordered) td,
.report-table-bordered:not(.table-bordered) th,
.report-table-bordered:not(.table-bordered) thead th {
  border: none;
  border-bottom: 1px solid $border-color;
}

.prompt-container .filter-container {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: $bold-border-top;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border-radius: 0.3rem;
  overflow-x: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
}

.filter-container h5 {
  font-size: $font-size-base * 1;
}

.show-prompt {
	color: #fff!important;
    /* background-color: #17A2B8!important; */
	background-color: #128192 !important; /* Meet 4.5:1 Contrast ratio requirement */
    border-color: #17A2B8!important;
}

@media only screen and (max-width: 768px) {
  .filter-container {
    border: none;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    //border-top: 2px solid #3a6ea7;
    margin-top: -16px;
    box-shadow: none;
    background-color: $white;
  }

  .filter-container .list-group-item {
    border: 1px solid #ccc;
  }

  .filter-container .list-group-item.active {
    background: white !important;
  }
}
.vld-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}

.vld-overlay.is-active {
  display: flex;
}

.vld-overlay.is-full-page {
  z-index: 9999;
  position: fixed;
}

.vld-overlay .vld-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.vld-overlay .vld-icon,
.vld-parent {
  position: relative;
}
.hide-default-date-text {
  color: #fff;
}
.designer-chart-container {
  min-height: 500px;
}
.value-field-list {
    max-height: 300px;
    overflow-y: auto;

    li {
        list-style: none;
    }

    margin-left:0;
    padding-left:0;
}
.lookup-list, .multi-select-list{
    .b-dropdown-form{
        padding:0.25rem 1.5rem;
    }
    .dropdown-item{
        padding:.5rem 1rem;
    }
    .btn-light{
        border-color:#ced4da;
    }
}
.multi-select-list{
    .dropdown-item{
        padding:.5rem 1rem .5rem .5rem;
        cursor:pointer;
    }
    i{
        color:#999999;
    }
}
.table .ten{
     border-bottom: 1px solid #dee2e6;
}
.table .stripe-grey{
    background:#E6E6E6;
}
.table .two{
     background:#ffffff;
}
.drillIcon{
    cursor:pointer;
}
.cell-bottom-border-default{
     border: none;
     border-bottom: 1px solid #dee2e6;
}
.grid-border-default{
     border: 1px solid #dee2e6;
}