.selected-items{
     padding:0;
     margin:0;
}
.list-item {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 5px 0;
     padding: 5px 10px;
     background: #f5f5f5;
     border: 1px solid #ddd;
     border-radius: 4px;
}
.delete-icon {
     cursor: pointer;
     color: red;
}