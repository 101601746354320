@import 'bootstrap/functions';
@import 'bootstrap/variables';

.detail-view {
	.list-group-item {
		display: none;
	}
}

// The hidden list items that contain the detail view
.list-group-item-detail-view {
	display: none;
	list-style: none;
	padding-top: 10px;
	padding: 1rem!important;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item-detail-view .h6 {
	margin: 10px;
}

.list-group-item-detail-view .row .col-lg-12 {padding-left: 1.2rem;padding-right: 1.3rem;}



.list-group-item.active .screen-indicator {
	transform: rotate(90deg);
}

.add-new-li {
	display: none;
}

.responsive-loading-message {
	font-weight: bold;
	color: #FF7256;
}

.single-row-maintainer {border: none;}

.load-single-row {
	margin-top: 15px;
}

@media only screen and (max-width: 768px) {

	

	.list-group-item:first-child {border-radius: 0;}

	.list-group-item {
		border-left: 0;
		border-right: 0;
		padding: 1.25rem;
		background: $mrcLightBG;
	}

	.list-group-item.active {background: #4686cb!important;}

	.list-group-item-detail-view {
		border: none;
		border-bottom: 1px solid #3a6ea7;
		padding: 1rem .5rem!important;
	}

	.list-group-item-detail-view .btn-group {
		margin: 0 auto;
	}

	.single-row-maintainer.form-vertical .form-row .form-group {
		border: none;
	}

	.single-row-maintainer {padding: 0;}

	.list-group-item .screen-indicator {margin-right: 8px;}


  }