span.jaxboxclass {
	position: absolute;
	z-index: 5001;
	background-color: #ffffff;
	border: 1px solid #DDDDDD;
	overflow: visible;
	display: none;
	left: 0;
	top: 0;
	font-family: "Open Sans",Helvetica,Arial,sans-serif;
	font-size: 13px;
}

span.spanMatchText {
	font-weight: bold;
}

span.normalEl {
	cursor: pointer;
	padding: 5px;
	background: #ffffff;
}

span.highEl {
	cursor: pointer;
	padding: 5px;
	background: #006dcc;
	color: #ffffff;
}

span.noMatchData {
	color: red;
}

div.ajaxboxclass {
	background-color: #fff;
	border: 1px solid #aaa;
	overflow: hidden;
	padding: 10px;
	z-index: 2000;
	box-shadow: #a8a8a8 1px 1px 5px;
	border-radius: 7px;
	height: auto!important;
}

.dsp_check label {
	display: inline-block;
}

.menu_body .list_div {
	display: inline-block;
	background-color: #fff;
	margin: 3px 3px;
	padding: 5px;
	line-height: 17px;
	border: 1px solid #cfcfcf;
	cursor: pointer;
	text-align: center;
}

.menu_body .list_div:hover {
	opacity: 0.6;
}
