@import 'bootstrap/functions';
@import 'bootstrap/variables';

// For SRM, left align lables on large screens
.form-labels-xl-right.form-horizontal {
	@media screen and (min-width:map-get($grid-breakpoints,'lg')) {
		label {
			text-align: right;
		}
		.custom-file label{
			text-align:left;
		}
	}
}

@media screen and (min-width:map-get($grid-breakpoints,'lg')) {
	.form-labels-shaded.form-horizontal label {
		background: #f1f1f1;
	}
	.form-labels-shaded.form-horizontal span label {
		background: transparent;
	}
}

.single-row-maintainer {
	@media screen and (max-width:map-get($grid-breakpoints,'sm')) {
		&.form-horizontal {
			label::before {
				display: block;
				margin-bottom: 5px;
				content: "";
				border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			}

			.form-group:first-child label:first-child::before {
				border-bottom: none;
				margin-bottom: 5px;
			}
		}

		&.form-vertical {
			.form-row .form-group {
				border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			}

			.form-group .form-control {
				margin-bottom: 5px;
			}
		}
	}
}

.single-row-maintainer {
	.form-group {
		margin-bottom: 0;
	}
}

// Add this class to form to make labels bold
.form-labels-bold {
	label {
		font-weight: bold;
		color: $bold-font-color;
	}
}

.key-label {
	font-weight: bold;
	color: $bold-font-color;
}


// Position the date rage picker icon
.calendar-picker-open {
	position: absolute;
	bottom: 10px;
	right: 24px;
	top: auto;
	cursor: pointer;
}

.advanced-search-row {
	.form-control {
		min-width: 75px;
	}
}

.form-control {	
	width: 100%;
	/*height: calc(1.5385em + .875rem + 2px);*/
    outline: 0;
    display: block;
    padding: $container-padding;
	font-size: $font-size-sm;
	font-weight: 400;
    line-height: $line-height-normal;
    color: $dark-font-color;
	background-clip: padding-box;
    position: relative;
	border: 1px solid #ced4da;
    white-space: nowrap;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    border-radius: $border-radius-xs;
	box-shadow: 0 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

select.form-control {margin-right: 2px;}


.fc-day-grid-event {
	margin: 1px 2px 0;
	padding: 3px;
  }


.single-row-maintainer {
	border: 2px solid #f7f7f9;
	padding: .75rem .875rem;
	//border-top: 2px solid $primary;
	border-radius: $border-radius-xs;
	//background-color: #f2f5f8;
}

.single-row-maintainer {
	.form-row {
		margin-left: 0;
		margin-right: 0;
	}

	label.col-form-label {
		padding-top: 10px;
		padding-bottom: 5px;
	}

	&.single-row-retrieval label.col-form-label {
		padding-top: 10px;
		padding-bottom: 5px;
		
	}
}

	.single-row-maintainer .row {
		margin-bottom: 0;
	}

	.single-row-maintainer .form-group div[class^=col-] {
		padding: 0.375rem;
	}
	.single-row-maintainer  .form-group .content-container div[class^=col-] {
		padding:0 15px 0 15px;;
	}
	.single-row-maintainer .form-group div[class^=col-].form-control-plaintext {
		padding-left: 15px;
	}

.form-control-inline {
	display: inline-block;
	width: auto;
	vertical-align: middle;
}

.input-group > .form-control {
	&.form-control-inline {
		width: auto;
		flex: initial;
	}
}

.input-group > span[mrcdropdown]{
	flex:1 1 auto;
	width:1%;
}
.input-group{
	white-space:nowrap;
}

.form-group.row {
	margin-left: 0;
	margin-right: 0;
}

input[type="radio"], input[type="checkbox"] {
	margin: 0 2px 0 0;
    vertical-align: middle;
}

#excelname {height:auto;}

#loadform1 {padding: 1rem;}

#loadform1 .form-group {
	padding: 1rem;
	margin-bottom: 0;
}

#loadform1 .card-header {
	font-weight: bold;
	background: $mrcLightBG;
}

#sel_delimiter {
	padding: .5rem;
    border: 1px solid $border-color;
    color: $bold-font-color;
    border-radius: 3px;
}

#text_table_head th {background: $mrcLightBG;}
#errortable {
	margin-top: 1rem;
    border-top: 2px solid #0097a7;
}

.prompt-container .filter-container .form-layout {
	border: 2px solid #f7f7f9;
	padding: 5px;
	
}

.single-row-retrieval .form-control {
	color: #757575;
	border: none;
	padding-top: 1px;
	padding-left: 0;
}

.single-row-retrieval label.col-form-label {
	padding-bottom: 0!important;
}

@media only screen and (max-width: 768px) {
	.form-control {
		height: auto;
		background-color: $white;
		font-size: inherit;
		padding: .66rem .875rem;
	}

	select.form-control:not([size]):not([multiple]) {height:auto;}

  }
  .invalid-feedback {
    font-size: 90%;
}
.single-row-maintainer-horizontal{
     .form-group{
          justify-content: space-between !important;
          display:flex;
          margin-top:10px;
    }
    .form-group > label{
          width:300px;
          text-align:right;
          margin-right:20px;
          align-self:flex-start;
   }
   .form-group >  label+*{
          width:100%;
          align-self:flex-start;
   }
   label{
          margin-bottom:0
   }
   .checkbox-container, .radio-button-container{
          padding-top:10px;
          padding-bottom:5px;
   }
}
.form-group-inline{
     justify-content: space-between !important;
     display:flex;
     margin-top:10px;
     > label{
          width:300px;
          text-align:right;
          margin-right:20px;
          align-self:flex-start;
   }
   >  label+*{
          width:100%;
          align-self:flex-start;
   }
   label{
          margin-bottom:0
   }
   .checkbox-container, .radio-button-container{
          padding-top:10px;
          padding-bottom:5px;
   }
}
.invalid-feedback.is-invalid{
     display:block;
}