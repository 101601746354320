.paging {

  input,
  div {
    margin-right: 3px;
  }
}

.hiddeninput {
  left: -5000px;
  position: absolute;
}

.btn-calendar-selections {
  margin-left: 10px;
}

.w-14 {
  width: 14.29%;
}

.fc-day-grid-event {
  margin: 1px 5px 0!important;
  padding: 3px!important;
}

@media only screen and (max-width: 768px) {
    #monthYear .form-control {
      margin-bottom: 5px;
    }

    .fc-header-toolbar h2 {font-size: 1.4rem;}
  }
