// Override secondary button color to make it lighter
.btn-secondary {
	color: #292b2c;
	border-color: $border-color;

	&:hover {
		background-color: #e6e6e6;
		border-color: #adadad;
	}
}

.btn {
	display: inline-block;
    font-weight: 400;
   // color: $dark-font-color;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //background-color: transparent;
    //border: 1px solid transparent;
    padding: $container-padding;
    font-size: $font-size-sm;
    line-height: $line-height-normal;
    border-radius: $border-radius-xs;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn.display-rls {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

@media screen and (max-width:map-get($grid-breakpoints,'sm')) {
	.btn {
		margin-bottom: 5px;
	}

	.btn.display-rls {
		margin-bottom: 0;
	}
}

.dropdown-menu {
	position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;    
    margin: .125rem 0 0;
    font-size: $font-size-sm;
    color: $dark-font-color;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: $border-radius-xs;

}




/* .action-header .maint_action, .action-header .dropdown-toggle {
    color: $primary!important;
    background-color: transparent!important;
    background-image: none!important;
    border-color: $primary!important;
}

.action-header .maint_action:hover, .action-header .dropdown-toggle:hover {
    color: #fff!important;
    background-color: $primary!important;
    background-image: none!important;
    border-color: $primary!important;
} */



@media only screen and (max-width: 768px) {
	.btn-secondary {
		padding: .5rem 1rem;
        font-size: inherit;
	}

    .btn {font-size: inherit;}
}