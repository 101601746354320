.card 	{  // margin-bottom: 1.25rem;
     /* box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%); */
     border-width: 0;
     position: relative;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: column;
     flex-direction: column;
     min-width: 0;
     word-wrap: break-word;
     background-color: #fff;
     background-clip: border-box;
     border: 1px solid rgba(0,0,0,.125);
     border-radius: 0.1875rem;
     }

.card-header {padding: 0.9rem 1.25rem;}
.card-header .card-title 	{margin-bottom: 0;}

h6.card-title {font-size: .9375rem; font-weight: 400;}
                         
.border-top-blue-2 {border-top: 2px solid #4686cb;}
.border-blue {border: 1px solid #4686cb;}
                         


.rounded-top-0 {
          border-top-left-radius: 0!important;
          border-top-right-radius: 0!important;
          }

.wmin-md-200 {min-width: 200px!important;}

.mpower-nav-tabs .nav-item {margin-bottom: -1px;}	
.mpower-nav-tabs {margin-bottom: 1.25rem;border-bottom: 1px solid #ddd;}

               
.mpower-nav-tabs .nav-link.active, 
.mpower-nav-tabs .nav-item.show .nav-link {
                                        color: #495057;
                                        background-color: #fff;
                                        border-color: #dee2e6 #dee2e6 #fff;
                                        }

.mpower-tab-content>.tab-pane {display: none;}
.mpower-tab-content>.active {display: block;}
                    
.mpower-nav-tabs .nav-link {
                         border: 1px solid transparent;
                         border-top-left-radius: 0;
                         border-top-right-radius: 0;
                         }


               
.nav-tabs-highlight .nav-link.active{border-top: 2px solid #4686cb;}
.nav-tabs-top .nav-link.active {border: none;border-top: 2px solid #4686cb;border-bottom: 1px solid #dee2e6;}											
.nav-tabs-bottom .nav-link.active {border: none; border-bottom: 2px solid #4686cb;}						

.nav-tabs-vertical-left .nav-link, 
.nav-tabs-vertical-left .nav-link.disabled, 
.nav-tabs-vertical-left .nav-link:focus, 
.nav-tabs-vertical-left .nav-link:hover {border-color: transparent #ddd transparent transparent ;}
.nav-tabs-vertical-left .nav-link.active {
                                        border-left: 2px solid #4686cb;
                                        border-top: 1px solid #ddd!important;
                                        border-bottom: 1px solid #ddd!important;
                                        border-right: none;
                                        }

.nav-tabs-vertical-right .nav-link, 
.nav-tabs-vertical-right .nav-link.disabled, 
.nav-tabs-vertical-right .nav-link:focus, 
.nav-tabs-vertical-right .nav-link:hover {border-color: transparent transparent transparent #ddd;}
.nav-tabs-vertical-right .nav-link.active {
                                        border-right: 2px solid #4686cb;
                                        border-top: 1px solid #ddd!important;
                                        border-bottom: 1px solid #ddd!important;
                                        border-left: none;
                                        }
                                        
.nav-tabs-solid {background-color: #f5f5f5;	border: 1px solid #ddd;	}
.nav-tabs-solid .nav-item.show .nav-link.active, 
.nav-tabs-solid .nav-link.active {color: #fff; background-color: #3a6ea7; border-radius: 0; border-color: #3a6ea7 transparent;}
.nav-tabs-solid .nav-link { border-width: 0;}
.nav-tabs-solid .nav-item {margin-bottom: 0;}
.nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-item.show .nav-link.active, 
.nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link.active {color: #fff; background-color: rgba(0,0,0,.1);}
.nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link {color: #fff;}

.bg-slate {background-color: #607d8b}

.border-slate {border-color: #607d8b}
.border-top-slate {border-top-color: #607d8b}
.border-bottom-slate {border-bottom-color: #607d8b}
.border-left-slate {border-left-color: #607d8b}
.border-right-slate {border-right-color: #607d8b}
.alpha-success {background-color: #e8f5e9;}				

.badge {
padding: 0.3125rem 0.375rem;
font-size: 90%;
font-weight: 500;
line-height: 1;
text-align: center;
white-space: nowrap;
vertical-align: baseline;
border-radius: 0.125rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.badge-pill{
     padding-right: 0.6em;
     padding-left: 0.6em;
     border-radius: 10rem;
}
.counter-icon {
font-size: 2rem;
position: absolute;
left: 50%;
margin-left: -1rem;
}

.avatar-lg {
height: 6rem;
width: 6rem;
}

.accordion h6 {font-size: .8rem;}
.accordion .card-header {background: white;}
.rounded-round {border-radius: 100px!important;}

.btn-lg {
    padding: 0.625rem 1.125rem;
    font-size: .875rem;
    line-height: 1.5;
}

.btn-sm {
    padding: 0.375rem 0.875rem;
    font-size: .75rem;
    line-height: 1.5;    
}

.btn-icon {
    padding-left: 0.62502rem;
    padding-right: 0.62502rem;
}