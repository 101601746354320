@import "bootstrap/functions";
@import "bootstrap/variables";

.table {
  background-color: $white;
  border-radius: 3px;
  margin-top: 0px;
}

.table td {
  padding: $table-cell-padding;
  font-size: $font-size-sm;
  border-top: none;
}

.table td.action-header {
  vertical-align: inherit;
  width: 75px;
}

.table .single .one {
  background: $table-accent-bg;
}

.table.fixed {
  position: relative;
}

.table th {
  padding: $table-cell-padding;
  white-space: nowrap;
  border-right: 1px solid $gray-250;
}
.table.table-collapse  {
     width:initial;
     td,th{
          padding:2px 5px;
     }
}
.table thead tr{
     background: $white;
     color: $bold-font-color;
     font-weight: $headings-font-weight;
     font-size: $font-size-sm;
}
.table th.action-header {
  text-align: center;
}

.table td,
.table th,
.table tr {
  -webkit-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
}

.table td:first-child,
.table th:first-child {
  border-left: none;
}

.table td:last-child,
.table th:last-child {
  border-right: none;
}

.table-bordered thead th,
.table-sky thead th,
.report-table-bordered thead th,
.table-bordered thead td,
.table-sky thead td,
.report-table-bordered thead td {
  border-bottom-width: 1px;
}

.table-content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: $bold-border-top;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: inline-flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  //flex-direction: column;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border-radius: 0.3rem;
  padding: 1rem 0;
  margin: 1px 0;
  //overflow-x:auto;
  min-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &.override-flex-block
  {
     display: block;
  }
}

.table-content .table:not(.table-bordered)  td,
.table-content .table:not(.table-bordered)  th,
.table-content .table:not(.table-bordered)  thead th {
  border: none;
  border-bottom: 1px solid $border-color;
  vertical-align: middle;
}
.table-content .table-bordered  td,
.table-content .table-bordered  th,
.table-content .table-bordered thead th {
  border: 1px solid $border-color;
}
.table-content .table.no-border td {
  border: none;
  border-bottom: none;
}
.table-content .table.table-bordered td {
  border: 1px solid $border-color;
}
.table-content .delete_row {
  padding: 5px;
  background: #555;
  color: $white;
  border-radius: 3px;
  cursor: pointer;
}

.mrc-chart-container {
  text-align: left !important;
}

#fixedtableheader0 {
  margin-top: $header-height;
}

a.sort-col, a.sorting-col {
  color: inherit;
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.stripe td {
  vertical-align: middle;
}

a.sort-col:hover, a.sorting-col:hover {
  text-decoration: none;
}

.td_right {
  text-align: right;
}

.td_left {
  text-align: left;
}

.sort-option-row {
  white-space: nowrap;
}

// table-sm can be added to tables to shrink. This will shrink the boostrap button a bit inside the table
.table-sm {
  .btn {
    padding: {
      top: 0.2rem;
      bottom: 0.2rem;
    }
  }
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.dltrow {
  text-align: center;
  display: block;
  width: 100%;
}

#sort .ui-sortable-handle {
  cursor: move;
}

#sort .ui-sortable-handle td:first-child:before {
  content: "\f0c9";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-right: 10px;
  color: #8798ab;
}

@media only screen and (max-width: 768px) {
  .table-content {
    border: none;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    //border-top: 2px solid #3a6ea7;
    //margin-top: -16px;
    box-shadow: none;
    background-color: $white;
  }

  .table td {
    font-size: inherit;
  }
}
.tableFixHead {
  overflow-y: auto;
  height: auto;
}
.tableFixHead table {
  border-collapse: collapse;
  width: 100%;
}
.tableFixHead th,
.tableFixHead td {
  padding: 8px 16px;
}
.tableFixHead tr:not(.paging-row) th {
  position: sticky;
  top: 59px;
  background: #fff;
  z-index:500;
}
.table-wrap{
     th{
          white-space:normal;
     }
}
