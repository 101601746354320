// Copied from old mrcmenu.html
.wrapper {
	min-height: 100%;
	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: auto;
}

#footer,
.push {
	height: 101px;
}

a.menu_link:link,
a.menu_link:visited {
	color: #777;
	text-decoration: none;
	cursor: pointer;
}

a.menu_link:hover {
	color: #555;
	text-decoration: underline;
	cursor: pointer;
}

.tabrow:before {
	position: absolute;
	content: " ";
	width: 100%;
	bottom: 0;
	left: 0;
	border-bottom: 1px solid #AAA;
	z-index: 1;
}

.tabrow {
	text-align: left;
	list-style: none;
	padding: 0;
	padding-left: 30px;
	line-height: 48px;
	height: 50px;
	overflow: hidden;
	font-size: 12px;
	font-weight: bold;
	position: relative;
	font-size: 15px;
}

.tabrow li {
	background: #D1D1D1;
	background: #FFF;
	display: inline-block;
	position: relative;
	z-index: 0;
	border-bottom: 1px solid #AAA;
	margin: 0 -5px;
	padding: 0 20px;
}

.tabrow a {
	color: #777;
	text-decoration: none;
}

.tabrow li.active {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4), inset 0 1px 0 #FFF;
	text-shadow: 0 1px #FFF;
	border: 1px solid #AAA;
	background: #FFF;
	color: #333;
	z-index: 2;
	border-bottom-color: #FFF;
}

.tabrow li.active a {
	color: #C41E03
}

.tabrow a:hover {
	color: #C41E03
}

.mainmenu {
	color: #000000;
	background: transparent;
	font: 8pt verdana;
}

.first_block {
	vertical-align: top;
	display: inline-block;
	padding-right: 20px
}

.secondary_block {
	vertical-align: top;
	display: inline-block;
	padding-right: 20px;
	border-left: 1px dotted #CFCFCF
}

.minor_style {
	font-size: 14px;
	padding-left: 25px;
	font-weight: bold;
	color: #555
}

.menu_text {
	display: block;
	padding-left: 25px;
	font-size: 14px;
	border: none;
}

.main_menu_style {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 18px;
	margin-left: 30px
}

.parent_div {
	margin-top: 15px;
	margin-left: 30px
}
