// Padding to push contents below fixed header
body {
	padding-top: $header-height;
	font-size: $font-size-sm;
	background-color: $mrcLightBG;
	color: $bold-font-color;
	margin:15px;
	font-family: $font-family-base;
}

.loading_ajax {
	padding: 10px;
	width: 150px;
	height: 75px;
	border: 1px solid #cfcfcf;
	background: $white;
	position: absolute;
	left: 0px;
	top: 0px;
	margin-left: 0px;
	text-align: center;
	font: bold 12pt verdana;
}


.btn_search {
	margin-left: -100000px;
	position: absolute;
}

// for filters in row
.row .input-group {
	margin-right: 5px;
}

// Styles to make header have a full width bottom border

#main {
	padding: 0!important;
	width: auto;
	//display: inherit;
    //max-width: 3000%;

}

.app-title {
	font-size: 19px;
	padding: .75rem 18px;
	border-bottom: 1px solid #ddd;
	margin: -15px;
	margin-bottom: 15px;
	background-color: $white;
	line-height: 1.5385;
}
.mrc-css-v1{
     div>.row+.row{
          margin-top:15px;
     }
     .app-title{
          margin:-15px -30px 0px -30px;
     }
}




// pagination styles

.pagination {
	display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: $border-radius-xs;
	font-size: $font-size-sm;
}


ul.pagination {
	margin-bottom: 0;
}

.page-link {
    position: relative;
    display: block;
    padding: .4375rem .875rem;
    margin-left: -1px;
    line-height: 1.5385;
    color: $dark-font-color;
    background-color: $white;
    border: 1px solid $border-light;
	text-align: center;
    min-width: 2.25003rem;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}

	.page-link .fa {
		color: #999;
	}


	.page-link:hover {
		z-index: 2;
		color: #333;
		text-decoration: none;
		background-color: #f5f5f5;
		border-color: $border-light;
	}

	.page-link:not(:disabled):not(.disabled) {
		cursor: pointer;
	}

.page-item.active .page-link {
    z-index: 1;
    color: $white;
    background-color: $primary;
    border-color: $primary;
}

.page-item.disabled .page-link {
    color: #999;
    pointer-events: none;
    cursor: auto;
    background-color: $white;
    border-color: $gray-150;
}

//Modal Styles

.modal-header {
	background: $mrcLightBG;
    color: #757474!important;
    border-top: 7px solid #589ce4;
	display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1.25rem;
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.modal-title {
	margin-bottom: 0;
    line-height: 1.5385;
	font-size: $font-size-base;
}

.modal-dialog .table-content {
	border: none;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
}

.modal-footer {background: $mrcLightBG;}


//Styles for imported apps in hover boxes

.ajaxboxinner .app-title {
	font-size:19px;
	padding: 1rem 2rem;	
	margin:0;	
	background-color:#f2f5f8;;	
	position:relative;
	width:100%;
	top:0;
}


.ajaxboxinner .content-container {padding-top:0} 
.ajaxboxinner .table-content{box-shadow: none;} 
.ajaxboxinner .form-control{height: auto;} 
.ajaxboxclass {
	width: auto!important; 
	min-width: 400px!important; 
	padding: 0!important;
    border-top: 5px solid #4686cb!important;} 
.ajaxboxinner .form-control-plaintext {display: inline-block;}
.ajaxboxinner .table-content {border: none;}

//Fonts should go in their own scss file

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: 	local('Roboto Thin'), local('Roboto-Thin'), url('fonts/Roboto-Thin.ttf') format('truetype');
  }
  
	  @font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 100;
		font-display: swap;
		src: 	local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('fonts/Roboto-ThinItalic.ttf') format('truetype');
	  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: 	local('Roboto Light'), local('Roboto-Light'), url('fonts/Roboto-Light.ttf') format('truetype');
  }
  
	  @font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 300;
		font-display: swap;
		src: 	local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/Roboto-LightItalic.ttf') format('truetype');
	  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: 	local('Roboto'), local('Roboto-Regular'), url('fonts/Roboto-Regular.ttf') format('truetype');
  }
  
	  @font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 400;
		font-display: swap;
		src:	local('Roboto Italic'), local('Roboto-Italic'), url('fonts/Roboto-Italic.ttf') format('truetype');
	  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: 	local('Roboto Medium'), local('Roboto-Medium'), url('fonts/Roboto-Medium.ttf') format('truetype');
  }
  
	  @font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 500;
		font-display: swap;
		src: 	local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('fonts/Roboto-MediumItalic.ttf') format('truetype');
	  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: 	local('Roboto Bold'), local('Roboto-Bold'), url('fonts/Roboto-Bold.ttf') format('truetype');
  }
  
	  @font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 700;
		font-display: swap;
		src: 	local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/Roboto-BoldItalic.ttf') format('truetype');
	  }
  
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: 	local('Roboto Black'), local('Roboto-Black'), url('fonts/Roboto-Black.ttf') format('truetype');
  }
  
	  @font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 900;
		font-display: swap;
		src: 	local('Roboto Black Italic'), local('Roboto-BlackItalic'), url('fonts/Roboto-BlackItalic.ttf') format('truetype');
	  }




	  @media only screen and (max-width: 768px) {
		body {
			background-color: white!important;
			font-size: .9375rem;
		}
		
		.app-title { 
			border-bottom: 1px solid #4686cb;
			text-align: center;
		}

		.modal {
			padding: 0!important;
		}

		.modal-dialog {
			margin: 0;
			min-height: 100%;
		}

	
		.modal-header {
			border-radius: 0;
		}
      }
      @media print {
        body {
          font-family: Arial, Helvetica, sans-serif;
          padding: 0;
          font-size: 12px;
          background-color: white!important;
          margin-left: 0;
          margin-right: 0;
          margin-top: 0;
          /*margin: 0; Warning: adding a margin of 0 breaks the pdf converter*/
        }
      
        .container-fluid {padding: 0;}
      
        .table {
          border-top: 2px solid #3a6ea7;
        }
      
        .table-content {
          border: none;
          border-top: none;
          position: relative;
          display: block;
          padding: 0;
          margin: 0;
          min-width: 100%;
          box-shadow: none;
        }
      
        .table tr {white-space: normal;}
      
          .table td {white-space:normal;}
      
        .btn {display: none;}
      
        .mrc-header {
              height: 30px;
              position: relative;
              padding: 10px;
              display: block!important;
              margin-bottom: 0px;
              margin-top: 0;
          }
      
          tbody.prompt {display: none;}
      
          .app-title {
              padding: 5px;
              margin: 0;
              margin-bottom: 5px;
              }
          .mrc-css-v1 .app-title {
               padding: 5px;
              margin: 0;
              margin-bottom: 5px;
           }
      }
      .mrc-notifications{ display:none}
      .badge{font-size:90%}
      .nav-item a.badge {margin:0.5rem 1rem}
      [data-show-when-click]:not(.show) {
          display:none !important;
          &.d-block {
              display:block !important
          }
          &.d-inline-block {
              display:inline-block !important
          }
          &.d-inline {
              display:inline !important
          }
          &.d-flex {
               display:flex !important
           }
           &.d-table {
               display:table !important
           }
           &.d-table-row {
               display: table-row !important
           }
     }
[class*=bg-]:not(.bg-transparent):not(.bg-light):not(.bg-white):not(.btn-outline):not(.bg-grey-200):not(.bg-grey-250):not(body) {
     color: #ffffff;
}
.font-size-xs {
    font-size: 0.75rem;
}
.font-size-sm {
    font-size: 0.875rem;
}
.font-size-md {
    font-size: 1rem;
}
.font-size-lg {
    font-size: 1.125rem;
}
.font-size-xl {
    font-size: 1.25rem;
}
.font-size-xxl {
    font-size: 18pt;
}
.font-size-xxxl {
    font-size: 1.50rem;
}
.font-size-xxxxl {
    font-size: 1.75rem;
}
.font-size-xxxxxl {
     font-size: 2rem;
 }
 .shadow-sm{
     box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important
 }
 .shadow-md{
     box-shadow:0 .5rem 1rem rgba(0,0,0,.15)!important
 }
 .shadow-lg{
     box-shadow:  0 1rem 3rem rgba(0,0,0,.175)!important;
 }
/* .text-left{
     text-align: left!important;
 }
 .text-right{
     text-align: right!important;
 }
 .text-center{
     text-align: center!important;
 }*/
 .word-wrap-enabled{
     white-space:normal!important;
 }
 .word-wrap-disabled{
     white-space:nowrap!important;
 }
 .bg-blue {
     background-color: #03a9f4;
 }
 .bg-danger-600 {
     background-color: #e53935;
 }
 .bg-success-400 {
     background-color: #4caf50;
 }
 .bg-indigo-400 {
     background-color: #5c6bc0;
 }
 .bg-indigo {
     background-color: #3f51b5;
 }
 .mrc-stat-value{display:none}
 .bg-slate{
     color:#ffffff;
 }