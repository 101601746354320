@import 'bootstrap/functions';
@import 'bootstrap/variables';

a i.remove-all {
	color: $gray-900
}

.reorder_column {
	position: relative;
}

.reorder_column th:hover .reorder-handle {
	display: inline-block;
}

.fa-sort.reorder-handle {
	cursor: grab;
	cursor: -webkit-grab;
	visibility: hidden;
}

th[data-col]:hover .fa-sort.reorder-handle {
	visibility: visible;
}

a.sort-col .fa-sort {
	display: none;
}
.trans-btn,.menu-title, .hasNotes{
	.spinner-grow{
		display:none;
	}
}