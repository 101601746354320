@import 'variables';

#mrc-menu .menu-title {
	font-size: 19px;
    padding: .75rem 18px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    background-color: #fff;
    line-height: 1.5385;
    position: fixed;
    width: 100%;
    top: 60px;
    left: 0;
	z-index: 999;
}

.text-menu-body, .card-menu-body {
	margin-top: 40px;
	margin-left: -15px;
}

	.text-menu-body #major-group-tab-content {padding-left: 1rem;}

ul.mrc-accordion-menu,
.mrc-accordion-menu ul {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style: none;
}

.mrc-accordion-menu .group-li {
	font-weight: bold;
}

.mrc-accordion-menu .group-li .menu-item {
	font-weight: normal;
}

.mrc-accordion-menu .minor-group ul,
.major-group ul {
	border-top: none;
}

.mrc-accordion-menu {
	border-bottom: 1px solid #d3d3d3;
	border-right: 1px solid #d3d3d3;
}

.mrc-accordion-menu ul {
	display: none;
}

.mrc-accordion-menu li {
	padding-left: 15px;
	border-top: 1px solid #cfcfcf;
}

.mrc-accordion-menu li.group-li {
	padding-bottom: 0;
}

.mrc-accordion-menu a {
	display: block;
	padding: 10px;
	width: 100%;
	border: none;
	color: #585858;
}

.mrc-accordion-menu a:hover {
	text-decoration: none;
	opacity: 0.6;
}

.mrc-accordion-menu .active {
	background: #f6f6f6;
	border-left: 5px solid #0044cc;
}

.mrc-accordion-menu .menu-item a {
	font-weight: normal;
}

.mrc-accordion-menu li.menu-item {
	border: none;
}

.mrc-accordion-menu .major-group-a {
	position: relative;
}

.page-nav {
	width: 350px;
	border: 1px solid #cfcfcf;
	height: calc(100% - #{$header-height});
	position: fixed;
	background: white;
	z-index: 2000;
	box-shadow: 2px 0 0 -1px rgba(190,190,190,1);
	padding-top: 10px;
	left: -350px;
    box-sizing:border-box;
    overflow:auto;
}

.page-nav h5 {
	padding-left: 10px;
}

.page-nav .mrc-accordion-menu {
	border-right: none;
}


//testing

.tab-content {
	border: 1px solid #ddd;
    margin: 0!important;
    border-top: none;
    padding: 2rem 0 1rem;
    background: white;
}

	.tab-content .tab-pane .tab-content {
		border: none!important;
		padding: 0;
	}

	.tab-content .card {
		//box-shadow: 0px 1px 4px 0 rgba(70,134,203,0.12);
		width: 16rem;
		min-height: 250px!important;
		border: 1px solid #ddd;
		//background-color: #fbfcfd;
		margin-left: 1rem;
		border-radius: .3rem;
	}
	.tab-content .card:hover {background: #f2f5f8;}

	.tab-content .card-body {
		padding: 1.875rem 1rem;
	}

	.tab-content .subminor-groups a.btn {
		padding: .4125rem 1.25rem;
		font-size: 13px;
	}

	.tab-content .card-body .fa {
		color: #4686cb!important;
		font-size: 45px!important;
	}

	.tab-content h4.card-title {font-size: 1.25rem;}
    body:not(.vue-body){
        .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
            color: #4686cb!important;
            background-color: #fff;
            border-color: #ddd;
            border-top: 2px solid #4686cb!important;
            border-bottom:none;
        }
    }
    .vue-body .wrap{
        top:0;
        margin:0;
    }

#mrc-menu .menu-body .card .btn {line-height: inherit!important;} 

.subminor-groups a.plain-text-link:link {line-height: 1.75rem;}

@media only screen and (max-width: 768px) {
	.nav-tabs .nav-link {
		width: 100%;
		border: 1px solid #ddd;
		border-radius: 0;
	}

	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
		//order: 99; 
		//margin-top: 5px;
		border-left: 4px solid #4686cb!important;
		border-top: 1px solid #ddd!important;
	}

	.tab-content .tab-pane .tab-content {padding: 0 1rem;}

	.tab-content .card {
		width: auto;
		margin-left: 0;
		min-width: 100%;
	}
}