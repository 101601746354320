@import 'bootstrap/functions';
@import 'bootstrap/variables';



$bold-font-color: #555;
$dark-font-color: #333;
$mrcLightBG: #f2f5f8!important;

$gray-300: #ccc;
$gray-250: #ddd;
$gray-150: #eee;
/*$primary: #4686cb !important;*/
$primary: #4370c3 !important;
$secondary: map-get($theme-colors,secondary);
$success: map-get($theme-colors,success);
$info: map-get($theme-colors,info);
$warning: map-get($theme-colors,warning);
$danger: map-get($theme-colors,danger);
$light: map-get($theme-colors,light);
$dark: map-get($theme-colors,dark);
$light-alt: map-get($theme-colors,light-alt);



// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem 1.25rem;
$table-cell-padding-sm:       .3rem;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                .8125rem;
$font-size-xs:                .6rem;
$font-size-md:                $font-size-base;
$font-size-xl:                ($font-size-base * 1.375);
$font-size-xxl:               ($font-size-base * 1.5);
$font-size-xxxl:               ($font-size-base * 1.625);
$font-size-xxxxl:               ($font-size-base * 1.75);
$font-size-xxxxxl:               ($font-size-base * 1.875);
$font-size-x2:                     ($font-size-base * 2);

// Define common padding and border radius sizes and more.

$line-height-normal:              1.5385 !default;


//$border-width:                1px !default;
$border-color:                  $gray-300 !default;
$border-light:                  $gray-250;
$bold-border-top:               2px solid #3a6ea7;

//$border-radius:               .25rem !default;
//$border-radius-lg:            .3rem !default;
//$border-radius-sm:            .2rem !default;
$border-radius-xs:              .1875rem !default;

$container-padding:             .4375rem .875rem;
//$component-active-color:      $white !default;
//$component-active-bg:         theme-color("primary") !default;

//$caret-width:                 .3em !default;

//$transition-base:             all .2s ease-in-out !default;
//$transition-fade:             opacity .15s linear !default;
//$transition-collapse:         height .35s ease !default;

$header-height: 60px;



