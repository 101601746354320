.selection-scroll-container {
	max-height: 400px;
	overflow-y: auto;

	.dropdown-menu.filter-relationship {
		max-height: 300px;
		overflow-y: scroll;
	}
}

.selection-scroll-container td, .selection-scroll-container th {border: 1px solid #ddd;}
.selection-scroll-container .table {margin-left: 0; border:1px solid #ccc;}